@mixin phone {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1025px) and (max-width: 1755px) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: 1756px) {
    @content;
  }
}

.fitlers {
  .loading {
    text-align: center;
  }

  .row {
    margin-right: 0px;
    margin-left: 0px;
    float: right;
    width: 83%;
    @include tablet {
      float: none;
      width: 100%;
      position: relative;
      top: 25px;
    }
    @include large-desktop {
      width: 76%;
      padding-top: 25px;
    }
    @include desktop {
      padding-top: 0px;
    }
  }

  display: inline;
  padding-top: 19px;
  @include tablet {
    width: 91%;
    padding: 0px 0px;
    margin-right: 12px;
  }

  @include phone {
    float: left;
    width: 78%;
  }
  .roundedSpan {
    color: #5cbcad;
    background-color: #def6f2;
    padding: 7px 14px;
    border-radius: 50px;
    font-weight: bold;
    cursor: pointer;
    @include tablet() {
      font-size: 12px;
      margin: 0px !important;
      padding: 7px 10px;
      display: block;
      padding: 0px 5px;
    }
    @include desktop() {
      font-size: 0.7rem;
      margin: 0px !important;
    }
  }

  .activeFilter {
    background-color: #5cbcad;
    color: #fff;
  }

  .material-icons {
    font-size: 15px;
    vertical-align: sub;
  }

  input {
    border: none;
    background-color: transparent;
    width: 30px;
    color: #5cbcad;
  }
}

.margin-first-graph {
  @include tablet() {
    margin-top: 0px !important;
  }
  @include large-desktop() {
    margin-top: 20px !important;
  }
}

.margin-filters {
  padding-right: 0px !important;
  padding-left: 0px !important;
  @include phone {
    margin-top: 6px;
  }
}

.input-range__track--active {
  background: #5cbcad;
}

.input-range__slider {
  background: #5cbcad;
  border: none;
}

.input-range__label-container {
  color: #5cbcad;
}

.country-birth-align {
  float: right;
  display: inline-block;
  text-align: center;
  @include phone() {
    float: none;
    display: inline-block;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.country-res-align {
  display: inline-block;
  text-align: center;
  @include phone() {
    margin-bottom: 10px;
  }
}

.input-range {
  height: 1rem;
  position: relative;
  width: 100%;
}

.graph-map {
}

.margin-barchat {
  @include tablet() {
    margin-top: 59px !important;
  }
}

.input-range__label--value {
  @include phone {
    position: absolute;
    top: -1.6rem;
  }
}

.spinner-style {
  text-align: center !important;
}

.spinner-style-1 {
  margin: 0 auto;
  text-align: center !important;
  width: fit-content;
}

.input-zoom {
  overflow: hidden !important;
  background-color: white;
  color: #5cbcad;
  border: 1px solid #5cbcad;
  @include large-desktop() {
    height: 42px;
    width: 42px;
  }
  @include desktop() {
    height: 28px;
    width: 28px;
  }
}
.input-contain {
  float: right;
  display: inline-grid;
  position: absolute;
  @include desktop() {
    top: 13px;
    right: 3%;
  }
  @include large-desktop() {
    top: 30px;
    right: 3%;
  }
  @include phone {
    top: 13px;
    right: 3%;
  }

  @include tablet() {
    top: 13px;
    right: 3%;
  }
}

.style-zoom {
  position: relative;
  height: 100%;
}

.spinner-style-1 {
  width: 600px;
  height: 300px;
  @include large-desktop() {
    width: 904px;
    height: 452px;
  }
  @include phone() {
    width: 385px;
    height: 193px;
  }
}
.spinner-style-1 svg {
  @include large-desktop() {
    margin-top: 2%;
    vertical-align: middle;
    text-align: center;
    width: 100%;
    height: 10%;
  }
}

.mapContainer {
  padding: 0;
  margin-bottom: 20px;
}
