@import "../../scss/colors";
@import "../../scss/medias";

.campaign {
  min-height: 30vh;
  margin-bottom: 15px;
  .title {
    font-size: 22px;
    font-weight: bold;
    @include sm {
      font-size: 16px;
    }
  }

  .parag {
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 1.8;
    color: #a1a1a1;
    @include sm {
      font-size: 16px;
    }
    @include xs {
      font-size: 14px;
    }
  }

  .campaignContainer {
    @media (min-width: 480px) and (min-height: 860px) {
      margin-top: 20vh;
    }
  }

  .participate {
    margin-top: 20px;
    cursor: pointer;
    color: $green;
    font-weight: bold;
    border: $green solid 1.5px;
    padding: 10px 15px;
    border-radius: 50px;
    text-align: center;
    @include sm {
      margin-top: 15px;
      padding: 8px 10px;
      border: $green solid 1.25px;
      border-radius: 25px;
    }
    @include xs {
      margin-top: 10px;
      padding: 5px 7px;
      border: $green solid 1px;
      border-radius: 25px;
    }
    a {
      text-decoration: none;
      color: $green;
      @include sm {
        font-size: 13px;
      }
      @include sm {
        font-size: 11px;
      }
    }
  }
}
