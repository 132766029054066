
@import "../../scss/colors";

.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

circle {
  stroke-width: 1;
  stroke: white;
}

.axis path,
.axis text {
  stroke: rgb(211, 211, 211);
}
g.axis:first-child g:nth-child(2) text {
  fill: none;
  stroke-width: 0;
}

.loading-gif {
  height: auto;
  width: 20%;
  position: absolute;
  top: auto;
  left: 50%;
  transform: translate(-50%);
}
.tabs {
  width: 100%;
  margin: 10px auto;
  display: inline-block;
  button {
    width: 33.33%;
    text-align: center;
    margin: auto;
    color: $green;
    outline:none;
    border: solid 1px $green;
    background-color: $white;
    &.active, &:hover {
      color: $white;
      background-color: $green;
      transition: 0.3s ease-in-out;
    }
  }
}