@mixin xs {
  @media (max-width: 576px) {
    @content;
  }
}
@mixin sm {
  @media (max-width: 767px) {
    @content;
  }
}
@mixin md {
  @media (max-width: 992px) {
    @content;
  }
}
@mixin lg {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin xl {
  @media (min-width: 1200px) {
    @content;
  }
}
