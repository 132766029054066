@import "../../scss/medias";
@import "../../scss/colors";

.row {
  margin-left: 0;
  margin-right: 0;
}

.no-margin {
  @media (min-width: 768px) {
    padding: 0;
  }
}

.app {
  .shareBloc {
    margin-top: 20%;
  }

  .gutties {
    margin-top: 30px;
    @media (max-width: 480px) and (max-height: 860px) {
      display: none;
    }
  }

  .charts {
    margin-top: 20px;
  }
}

.graphs-grid {
  padding: 5% 5% 0;
  @include sm {
    padding: 0;
  }
}

.loading-block {
  padding-top: 35px;
  min-height: 300px;
}

.graphContainer {
  display: flex;
  justify-content: center;
  height: min-content;
  flex-direction: column;
}

.infoIcon {
  color: #f9c888;
  vertical-align: sub;
  cursor: pointer;
}

.background-cover {
  background: url(../../public/background2.png);
  background-size: cover;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  @include sm {
    background: unset;
  }
}

#campaign {
  margin-top: 10vh;
  @include sm {
    margin-top: 40vh;
  }
}

.mobile-background {
  @include sm {
    background: url(../../public/background2.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 35%;
  }
}

.chat-btn__placement {
  position: fixed;
  bottom: 100px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $orange;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: $orange;
  @include sm {
    height: 55px;
    width: 55px;
  }
}

.react-icons {
  height: 60px;
  width: 60px;
  @include sm {
    height: 35px;
    width: 35px;
  }
}

.chat-link {
  color: white;
  &:hover {
    color: white;
  }
}