@import "../../scss/medias";
@import "../../scss/colors";

.computer-modal {
  .modal-dialog {
    margin: 0;
    max-width: unset;
    width: 100vw;
    height: 100vh;
    padding: 5% 10%;
    @include sm {
      width: auto;
    }
  }

  .ModalContent {
    padding: 20px;

    .titlecolumn {
      width: 100%;
    }

    .titleModal {
      font-size: 20px;
      font-weight: bold;
    }
    p {
      color: $grey;
    }

    .slick-slide {
      height: 65vh;
      min-height: 620px;
    }

    .Slider {
      text-align: center;
      img {
        height: 35vh;
        min-height: 300px;
        width: auto;
        margin: 0 auto 0 auto;
        @include md {
          height: 30vh;
        }
      }
    }

    .slick-dots {
      bottom: 0;
      li.slick-active button:before {
        color: $orange;
        font-size: 9px;
      }
    }

    .slick-prev {
      display: inline-block;
      position: absolute;
      text-align: right;
      top: initial;
      height: 40px;
      width: 50%;
      bottom: 40px;
      opacity: 1 !important;
      z-index: 1;
    }

    .slick-next {
      position: absolute;
      display: inline-block;
      top: initial;
      text-align: left;
      width: 50%;
      height: 40px;
      bottom: 40px;
      color: black;
      opacity: 1 !important;
    }

    .slick-prev:before {
      content: "Previous";
      height: 40px;
      color: $orange;
      padding: 10px 35px;
      border: 2px solid $orange;
      border-radius: 20px;
    }
    .slick-next:before {
      content: "Next";
      height: 40px;
      width: 80%;
      padding: 10px 40px;
      border: 2px solid $orange;
      background-color: $orange;
      border-radius: 20px;
    }
  }

  .modalOpen {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: $black;
    z-index: 1;
  }

  .modalClose {
    visibility: hidden;
  }
}

.mobile-modal {
  .modal-dialog {
    @include sm {
      width: 100vw;
      max-width: unset;
      height: 100vh;
      min-height: 500px;
      padding: 0;
      margin: 0;

      .modal-content {
        height: 100vh;

        .modal-close {
          position: absolute;
          font-size: 27px;
          color: $grey;
          top: 10px;
          right: 10px;
          z-index: 2;
          cursor: pointer;
        }

        .mobile-header {
          height: 10vh;
          min-height: 55px;
          padding: 5px;
          color: $orange;
          font-size: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;

          #cross {
            color: $grey;
            font-size: 30px;
          }
        }

        .mobile-content {
          height: 80vh;
          min-height: 300px;

          .slick-slider {
            height: 100%;
          }

          .Slider {
            height: 80vh;
            min-height: 450px;
          }

          .modal-title,
          .modal-description,
          .modal-image {
            padding: 0 30px;
            @include sm {
              margin: auto;
            }
          }

          .modal-title {
            height: 10vh;
            min-height: 35px;
            margin-top: 10vh;
            font-size: 20px;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }

          .modal-description {
            height: 60vh;
            min-height: 220px;
            overflow: auto;
            font-size: 14px;
            color: grey;
            display: flex;
            justify-content: center;
            text-align: center;
            p {
              z-index: 1;
            }
          }

          .carousel-images {
            height: 30vh;
            width: auto;
            position: absolute;
            top: 50vh;
            left: 50%;
            transform: translate(-50%, 0);
            opacity: 0.6;
          }

          .slick-slide {
            height: 100vh;
          }

          .slick-prev {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 10vh;
            width: fit-content;
            transform: translate(0, 0);
            font-size: 20px;
            z-index: 2;
          }
          .slick-next {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 80vh;
            left: 0px;
            height: 10vh;
            width: 100%;
            transform: translate(0, 0);
          }

          .slick-prev:before {
            font-family: initial;
            content: "< Previous";
            color: $orange;
            padding: 10px 35px;
          }

          .slick-next:before {
            font-family: initial;
            content: "Next";
            color: $white;
            width: max-content;
            font-weight: bold;
            padding: 10px 35px;
            border: 2px solid $orange;
            background-color: $orange;
            border-radius: 20px;
          }

          .slick-dots {
            top: 90vh;
            li.slick-active button:before {
              color: $orange;
              font-size: 9px;
            }
          }
        }

        .mobile-next {
          height: 10vh;
          min-height: 55px;
        }

        .mobile-dots {
          height: 10vh;
          min-height: 55px;
        }
      }
    }
  }
}
