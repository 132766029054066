@import "../../scss/colors";
@import "../../scss/medias";

.mobile-modal {
  display: none;
  @include sm {
    display: block;
  }
}

.modal-header__title {
  position: sticky;
  top: 0;
  height: 8vh;
  display: flex;
  align-items: center;
  padding: 20px;
  min-height: 60px;
  background: $lightgreen;
  color: $white;
  font-weight: bold;
  z-index: 1;
}

.modal-subtitle {
  color: $grey;
  font-weight: bold;
  height: 5vh;
  display: flex;
  align-items: center;
  padding: 25px;
}

.modal-buttons {
  padding: 25px;
}

.modal-filter-footer {
  display: flex;
  background: $white;
  position: fixed;
  bottom: 10vh;
  width: 99%;
  padding: 25px;
  @include sm {
    padding: 10px;
  }
}

.activeFilter {
  background-color: $green !important;
  color: $white !important;
}

.iconFilter {
  width: 12px;
}

.spanFilter {
  width: 100%;
  display: inline-block;
}

.roundedSpan {
  color: $green;
  background-color: $white;
  padding: 7px 14px;
  border: solid 1px $green;
  border-radius: 50px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}

.input-range__label-container {
  display: none;
}

.grey {
  color: $grey;
  font-weight: bold;
  font-size: 12px;
}

.value-input__text {
  font-size: 12px;
  color: $green;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 80%;
  border: 0;
  border-top: 1px solid $grey;
}

#close-button__color {
  color: $white;
}

#overflow-y__auto {
  overflow-y: auto;
}

#save_filters {
  background: $orange;
  border: solid 1px $orange;
  color: $white;
  box-shadow: 0px 0px 2px $grey;
}

.dropdown-select__padding {
  padding: 0 10%;
}

.error {
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  color: $red;
}