@import "../../scss/_medias.scss";
@import "../../scss/colors";

.container-chat {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 90vh;
  min-height: 400px;
  background-color: white;
  padding: 3%;
  @include sm {
    padding: 0;
  }
}

.type-name-input {
  display: flex;
  width: 30%;
  padding: 0 1%;

  @include sm {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
  }
}

.input-message-style {
  height: 100px;
}

form.index-search-form {
  height: 10vh;
  background-color: #f5f6fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 2%;
}

.index-search-form button[name="submit"] {
  height: fit-content;
}

input.search-box {
  height: 100%;
  width: 100%;
  font-family: FontAwesome;
  padding: 2px 0;
  background-color: #f5f6fa;
  border: none;
  outline: none;
}

input.search-box::placeholder {
  color: #5cbdaf;
  padding-left: 10px;
}

.messages-list {
  height: max-content;
  overflow: auto;
  padding: 0 1%;
  @include sm {
    padding: 0 10px;
  }
}

.button_base {
  margin-left: 5px;
  padding: 8px 10px;
  background-color: #5cbdaf;
  color: white;
  border-radius: 16px;
  border: none;
  outline: none !important;
}

.container-message {
  max-width: 100%;
  background-color: #e6e6e6;
  border-radius: 11px 11px 11px 0px;
  word-wrap: break-word;
  padding: 4%;
}

.date-span {
  float: right;
}

.container-chat-message {
  width: 30%;
  margin-bottom: 20px;
  @include sm {
    width: 75%;
  }
}

.date-span {
  font-style: italic;
  color: gray;
  font-size: 13px;
}

._self {
  padding-right: 3px;
  margin-left: 70%;
  @include sm {
    margin-left: 25%;
  }
}

.container-main {
  height: 100vh;
  background-color: #c4ede7;
  margin: 0;
  padding: 1%;
  overflow: auto;
}

.pointer {
  cursor: pointer;
}

.light {
  background-color:#93dbcf  ;
}

.chat-header {
  height: 60px;
  max-height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1%;
  @include sm {
    height: 55px;
  }
}

.list-group-item {
  position: relative;
  display: flex;
  height: 85%;
  justify-content: center;
  padding: 10px;
  align-items: center;
}

.chat-logo {
  width: auto;
  height: 50px;

  @include sm {
    height: 40px;
  }
}

.active-tab {
  text-decoration: underline;
  font-weight: bold;
}

.link__like {
  text-decoration: none;
  color: $black;
}

.button-like {
  color: $green;
  text-decoration: underline;
  cursor: pointer;
}

.horizontal-centered {
  display: flex;
  justify-content: center;
}