@import "../../scss/_medias.scss";

.footer {
  margin: 0;
  width: 100%;
  @include sm {
    margin: 0 0 82px;
  }

  .socialNetwork {
    padding: 5px;
    width: 30px;
    margin-top: 1em;
    cursor: pointer;
  }
  .logo {
    display: inline;
    text-align: center;
    img {
      height: 40px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .byLabel {
    float: right;
    margin-top: 1rem;
  }

  .footerLabels {
    margin-top: 1rem;
    span {
      margin-right: 8px;
      text-decoration: underline;
      font-size: 10px;
      color: #fff;
      cursor: pointer;
    }
  }

  .modalheader {
    padding-bottom: 2em;
  }

  .modalcontent {
    height: 65vh;
    padding: 1em 0 1em;
    overflow-y: auto;

    .modaltable {
      width: 25%;
    }
  }

  .modalfooter {
    padding: 2em 0 2em;
  }

  .privacy-line {
    display: flex;
    justify-content: flex-start;
    @include sm {
      justify-content: center;
      // remove default bootstrap margins in mobile
      margin: 0 -15px;
      padding: 0;
      width: 100vw;
      padding: 10px;
      text-align: center;
    }
    .link {
      cursor: pointer;
      text-decoration: underline;
      font-size: 14px;
      text-align: center;
      color: grey;

      @include sm {
        font-size: 12px;
      }
      @include xs {
        font-size: 11px;
      }
    }
  }

  .statements {
    a {
      margin-right: 20px;
      color: #a1a1a1;
      font-size: 14px;
      text-decoration: underline;
    }
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logos-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  @include sm {
    flex-direction: column;
  }
  .logoFooter {
    width: auto;
    height: 80px;
    margin: 10px;
    @include sm {
      height: 60px;
    }
    @include xs {
      height: 40px;
    }
  }

  // case of microbiome discovery image (very important width)
  #microbiome-img {
    width: auto;
    height: 30px;
    @include sm {
      height: 25px;
    }
    @include xs {
      height: 20px;
    }
  }
}

#linkedin-share__computer {
  @include sm {
    display: none;
  }
}