@import "../../scss/colors";
@import "../../scss/medias";

.xAxis line {
  stroke: #b8b8b8;
}

.modal-tooltip {
  left: 55%;
  top: 30%;
  background-color: $white;
  border: 1px solid $blue;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20% 0 0 40%;
  padding: 1.5%;
}

.image-modal {
  @media (max-width: 768px) {
    display: none;
  }
}

.tooltip-content {
  padding: 20px;
  font-size: 13px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $blue;
  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
    font-size: 11px;
  }
  @media (max-width: 576px) {
    font-size: 8px;
  }
}

.graphLabel {
  font-weight: bolder;
  color: $blue;
  display: flex;
  justify-content: center;
}

.title {
  font-size: 17px;
  width: 100%;
  text-align: center;
  @media (max-width: 576px) {
    font-size: 13px;
    width: 100%;
  }
}

.subtitle {
  position: absolute;
  top: 5%;
  right: 12%;

  font-size: 10px;
  text-align: center;
  @include md {
    font-size: 9px;
  }
  @include sm {
    font-size: 8px;
  }
  @include xs {
    font-size: 7px;
  }
}

.rank {
  margin-top: 20px;
  position: absolute;
  right: 3%;
  top: 10%;
  display: flex;
  align-items: flex-end;
  text-align: center;
  font-size: 10px;
  width: auto;
  @include md {
    font-size: 9px;
  }
  @include sm {
    font-size: 8px;
  }
  @include xs {
    font-size: 7px;
  }
}

#rank__map {
  top: 20%;
  @include xs {
    top: 30%;
  }
}

.microbiome-profile {
  display: flex;
  justify-content: flex-end;
  font-weight: bolder;
  color: $blue;
}

.legend {
  display: block;
  min-width: 100px;
  width: 20%;
  margin-top: 12%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
}

.legend-title {
  display: flex;
  text-align: center;
  height: 15%;
  margin-left: -70px;
  width: auto;
  font-size: 11px;
  color: $blue;
}

.legend-percentages {
  display: flex;
  pointer-events: none;
  flex-direction: column;
  height: 66%;
  float: left;
  width: 30%;
}

.category-percentage {
  height: 10%;
  font-size: 10.5px;
  color: $blue;
  @include sm {
    font-size: 8px;
    display: none;
  }
}

.axisTransparent path {
  stroke-opacity: 0;
}

line {
  stroke: #b8b8b8;
}

.padding-borders {
  padding: 5px;
}

.borders {
  border: 1px solid #d8f5f1;
}

.graph-padding {
  padding-top: 20px;
  min-height: 300px;
}

.loading-gif {
  height: auto;
  width: 20%;
  position: absolute;
  top: auto;
  left: 50%;
  transform: translate(-50%);
}

.barchart-label {
  font-weight: bolder;
  color: $blue;
  display: flex;
  justify-content: space-between;
}

.legends-label {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
