.axis path,
.axis line {
  fill: none;
  stroke: #000000;
  shape-rendering: crispEdges;
}

circle {
  stroke-width: 1;
  stroke: #ffffff;
}

.axis path,
.axis text {
  stroke: #d3d3d3;
}
g.axis:first-child g:nth-child(2) text {
  fill: none;
  stroke-width: 0;
}

.loading-gif {
  height: auto;
  width: 20%;
  position: absolute;
  top: auto;
  left: 50%;
  transform: translate(-50%);
}
