@import "../../scss/medias";
@import "../../scss/colors";

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.header {
  width: 100%;
  height: 100vh;
  min-height: 568px;
  background: url("../../public/background.jpg") center;
  background-size: cover;
  border-bottom: 1px solid $green;
  font-weight: normal;
  color: $white;
  @include sm () {
    margin-bottom: 25px;
  }
  
  .logo {
    height: 50px;
    @include sm {
      height: auto;
      width: 100%;
      padding: 10px;
    }
  }

  .head-container {
    width: 70%;
    @include lg {
      width: 100%;
    }
    height: 91vh;
    min-height: 516px;
    padding: 20vh 8% 5vh;
    font-size: 16px;

    @include sm {
      width: 100%;
      padding: 10vh 2% 5vh;
      font-size: 14px;
    }
    @include xs {
      font-size: 13px;
    }
    .mainContent {
      height: 52vh;
      min-height: 300px;
      padding: 5% 0;
      @include md {
        height: 62vh;
      }
      .description {
        overflow: auto;
        max-height: 100%;
        min-height: 227px;
        font-weight: bold;
        ::-webkit-scrollbar {
          width: 2px;
        }
        @include sm {
          max-height: 40vh;
          padding-top: 5vh;
        }
      }
    }

    .logoContainer {
      height: 7vh;
      min-height: 39px;
    }
  }

  .analyseLabel {
    margin: 10px;
    font-size: 1.3rem;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
  .chatLabel {
    margin: 10px 30px 10px 10px;
    font-size: 1.3rem;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }

  .headerLabels {
    float: right;
    margin-top: 10px;
    line-height: 1.5;
  }

  .activeNav {
    font-weight: bold;
    border-bottom: 2px solid #fff;
  }

  .scrollContainer {
    position: absolute;
    bottom: 0;
    height: 9vh;
    left: 50%;
    transform: translate(-50%);
    min-height: 50px;
    text-align: center;
  }

  .scroll {
    font-size: 20px;
    left: 0;
  }

  .material-icons {
    cursor: pointer;
    font-size: 70px;
  }

  .text {
    height: 91vh;
    font-size: 16px;
    @include sm {
      width: 100%;
      font-size: 14px;
    }
    @include xs {
      width: 100%;
      font-size: 12px;
    }
  }

  .howItWorks {
    font-weight: bolder;
    padding: 10px 30px;
    border: 2px solid #fff;
    border-radius: 20px;
    cursor: pointer;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobile-center {
    @include sm {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
