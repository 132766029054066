.shareButtons {
    padding-top: 1% !important;
    button {
        margin: 5px 5px 5px 0;

        circle {
            fill: rgb(209, 209, 209);
        }
    }

    .shareTitle {
        display: block;
        font-weight: bold;
    }
    .col {
        padding-right: 0;
        padding-left: 0;
      }

      .noMarginLeft {
          div {
              padding-left: 0;

          }
      }
}