@import "../../scss/medias";
@import "../../scss/colors";

.bloc-padding {
  padding: 0;
  @include sm() {
    padding: 25px;
  }
}

.filter-bloc__text-placement {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fitlersBloc {
  width: 100%;
  .margin-filters {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 90%;
  }

  .bold {
    font-weight: bold;
  }

  .defineFeatures {
    margin-bottom: 20px;
  }

  .roundedSpan {
    color: $green;
    background-color: $white;
    padding: 7px 14px;
    border: solid 1px $green;
    border-radius: 50px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
  }

  .activeFilter {
    background-color: $green;
    color: $white;
  }

  .material-icons {
    font-size: 15px;
    vertical-align: sub;
  }

  input {
    border: none;
    background-color: transparent;
    width: 30px;
    color: $green;
  }

  .valueAge {
    font-size: 12px;
    color: $green;
  }

  .grey {
    color: rgb(172, 172, 172);
    font-weight: bold;
  }

  .rangeAge {
    font-size: 10px;
    margin-top: 20px;
    text-align: center;
  }

  .titleFilters {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  .input-range__track--active {
    background: $green;
  }

  .input-range__slider {
    background: $white;
    border: solid 1px $green;
  }

  .input-range__label-container {
    display: none;
  }

  .clearBloc {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .filters-grid {
    padding: 0, 5%;
    @include sm {
      padding: 0;
    }
  }

  .clearAll {
    margin-top: 20px;
    text-transform: uppercase;
    color: $grey;
    text-decoration: underline;
    font-weight: bolder;
    cursor: pointer;
  }

  .spanFilter {
    width: 100%;
    display: inline-block;
  }
}

.separator {
  border-right: 1px solid $grey;
}

#age-filters {
  min-width: 153px;
}

#filter-bar {
  display: none;
  @include sm {
    display: flex;
    background-color: $white;
    position: fixed;
    height: 10vh;
    min-height: 50px;
    bottom: 0;
    width: 100%;
    transition: top 0.3s;
    margin-left: -15px;
    z-index: 9999;
    justify-content: center;
    align-items: center;
    border: 1px solid $grey;
    a {
      text-align: center;
      text-decoration: none;
      color: $grey;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
      align-items: center;
    }
  }
}

.iconFilter {
  width: 18px;
}

.dietIcon {
  width: 100%;
  cursor: pointer;
}

.computer-bloc {
  display: block;
  @include sm {
    display: none;
  }
}

.mobile-block {
  display: none;
  @include sm {
    display: block;
  }
}

.footer-element {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 33.333333%;
}
